import { useState, useEffect } from 'react'
import { getLocation } from 'auth/api.client.js'
import { useToast } from 'toast/ToastContext'

const useCoordsAndIP = () => {
  const [userIP, setUserIP] = useState(null)
  const [userLocation, setUserLocation] = useState(null)
  const [isLocationEnabled, setIsLocationEnabled] = useState(true)
  const { alert } = useToast()

  const getUserIP = () => {
    const response = getLocation()
    response.then((e) => {
      setUserIP({
        ip: e.data.ip,
      })
    })
  }

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          setUserLocation({ latitude, longitude })
          setIsLocationEnabled(true)
        },
        (error) => {
          setIsLocationEnabled(false)
          console.error('Error getting user location:', error)
        }
      )
    } else {
      setUserLocation({ latitude: userIP.latitude, longitude: userIP.longitude })
    }
  }

  useEffect(() => {
    getUserIP()
    getUserLocation()
    if (!isLocationEnabled) {
      alert('Location is not enabled. Please enable location services to proceed.')
    }
  }, [isLocationEnabled, alert])

  return { userIP, userLocation }
}

export default useCoordsAndIP
